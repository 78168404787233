@keyframes pulse {
  0%, 100% {
    border-color: #464646;
  }
  50% {
    border-color: #FFFFFF;
  }
}

.power-button {
  height: 24px;
  width: 24px;
  border: 1px solid #464646;
  border-radius: 9999px;
  cursor: pointer;
}

.power-button:not(.active) {
  animation: pulse 2s ease-in-out infinite;
}

.power-button.active {
  border-color: #464646;
}


